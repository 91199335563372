.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60vh;
}

.loading {
  width: 100px;
  height: 100px;
}
