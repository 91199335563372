.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.certificate {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 300px;
    margin-top: 100px;
  }
}
.file {
  /* display: flex; */
  /* justify-content: center; */
  width: 100%;
  /* border-style: dotted; */
  padding: 15px 0px;
  border-bottom: 2px solid var(--marketplaceColorDark);
}
.fileUpload {
  display: flex;
  width: 99%;
  margin-bottom: 15px;
  margin-top: 15px;
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
}
.delete {
  border-radius: 50%;
  height: 29px;
  width: 35px;
  padding: 0px 8px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  background-color: red;
  color: white;
  border: none;
  &:hover {
    background-color: #8d0c0c;
  }
}
.link {
  /* line-height: 24px; */
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 350px;
  align-self: flex-end;
  margin-right: auto;
}
.logo {
  display: flex;
  justify-content: flex-end;
  font-size: 30px;
}
.uploadLabel {
  /* margin-bottom: -1rem; */
  align-self: flex-end;
  font-size: 15px;
  color: lightgray;
  margin-right: auto;
}
.loading {
  display: flex;
  justify-content: center;
}
.selectPlaceholder {
  color: red !important;
}
