*, ::before, ::after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.right-0 {
  right: 0px
}

.right-\[1rem\] {
  right: 1rem
}

.top-1\/2 {
  top: 50%
}

.top-4 {
  top: 1rem
}

.right-4 {
  right: 1rem
}

.bottom-12 {
  bottom: 3rem
}

.left-1\/2 {
  left: 50%
}

.z-10 {
  z-index: 10
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.col-span-3 {
  grid-column: span 3 / span 3
}

.col-span-5 {
  grid-column: span 5 / span 5
}

.m-0 {
  margin: 0px
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.ml-1 {
  margin-left: 0.25rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mr-1 {
  margin-right: 0.25rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mt-0 {
  margin-top: 0px
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-8 {
  margin-top: 2rem
}

.mr-auto {
  margin-right: auto
}

.ml-auto {
  margin-left: auto
}

.mr-6 {
  margin-right: 1.5rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.mb-0 {
  margin-bottom: 0px
}

.mt-1 {
  margin-top: 0.25rem
}

.mr-4 {
  margin-right: 1rem
}

.ml-4 {
  margin-left: 1rem
}

.ml-6 {
  margin-left: 1.5rem
}

.mr-2 {
  margin-right: 0.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mr-12 {
  margin-right: 3rem
}

.mb-12 {
  margin-bottom: 3rem
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.h-40 {
  height: 10rem
}

.h-full {
  height: 100%
}

.h-24 {
  height: 6rem
}

.h-\[1px\] {
  height: 1px
}

.h-64 {
  height: 16rem
}

.h-screen {
  height: 100vh
}

.h-32 {
  height: 8rem
}

.h-12 {
  height: 3rem
}

.h-\[199px\] {
  height: 199px
}

.min-h-screen {
  min-height: 100vh
}

.w-full {
  width: 100%
}

.w-24 {
  width: 6rem
}

.w-32 {
  width: 8rem
}

.w-screen {
  width: 100vw
}

.w-12 {
  width: 3rem
}

.min-w-\[400px\] {
  min-width: 400px
}

.max-w-3xl {
  max-width: 48rem
}

.max-w-custom {
  max-width: 57rem
}

.flex-1 {
  flex: 1 1 0%
}

.shrink {
  flex-shrink: 1
}

.grow {
  flex-grow: 1
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.cursor-not-allowed {
  cursor: not-allowed
}

.select-none {
  -webkit-user-select: none;
          user-select: none
}

.resize-none {
  resize: none
}

.resize {
  resize: both
}

.list-inside {
  list-style-position: inside
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr))
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.place-items-center {
  place-items: center
}

.items-center {
  align-items: center
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-around {
  justify-content: space-around
}

.gap-4 {
  gap: 1rem
}

.gap-2 {
  gap: 0.5rem
}

.gap-x-6 {
  column-gap: 1.5rem
}

.gap-y-2 {
  row-gap: 0.5rem
}

.gap-x-4 {
  column-gap: 1rem
}

.gap-x-1 {
  column-gap: 0.25rem
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
}

.self-center {
  align-self: center
}

.justify-self-end {
  justify-self: end
}

.overflow-hidden {
  overflow: hidden
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.break-all {
  word-break: break-all
}

.rounded {
  border-radius: 0.25rem
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-full {
  border-radius: 9999px
}

.border {
  border-width: 1px
}

.border-2 {
  border-width: 2px
}

.border-0 {
  border-width: 0px
}

.border-\[1px\] {
  border-width: 1px
}

.border-\[2px\] {
  border-width: 2px
}

.border-b {
  border-bottom-width: 1px
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-none {
  border-style: none
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity))
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.border-marketplaceColor {
  --tw-border-opacity: 1;
  border-color: rgb(5 192 5 / var(--tw-border-opacity))
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity))
}

.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 202 202 / var(--tw-border-opacity))
}

.border-gray-400\/75 {
  border-color: rgb(156 163 175 / 0.75)
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity))
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity))
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity))
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.border-b-gray-100 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(243 244 246 / var(--tw-border-opacity))
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.bg-marketplaceColor {
  --tw-bg-opacity: 1;
  background-color: rgb(5 192 5 / var(--tw-bg-opacity))
}

.bg-marketplaceColorDark {
  --tw-bg-opacity: 1;
  background-color: rgb(13 75 13 / var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.bg-gray-100\/75 {
  background-color: rgb(243 244 246 / 0.75)
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity))
}

.bg-\[\#e5eaf5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 234 245 / var(--tw-bg-opacity))
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity))
}

.object-cover {
  object-fit: cover
}

.p-8 {
  padding: 2rem
}

.p-24 {
  padding: 6rem
}

.p-2 {
  padding: 0.5rem
}

.p-4 {
  padding: 1rem
}

.p-12 {
  padding: 3rem
}

.p-6 {
  padding: 1.5rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.pl-0 {
  padding-left: 0px
}

.pl-3 {
  padding-left: 0.75rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pt-0 {
  padding-top: 0px
}

.pb-4 {
  padding-bottom: 1rem
}

.pl-6 {
  padding-left: 1.5rem
}

.pr-6 {
  padding-right: 1.5rem
}

.text-center {
  text-align: center
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.font-serif {
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.font-normal {
  font-weight: 400
}

.font-extrabold {
  font-weight: 800
}

.font-light {
  font-weight: 300
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.leading-snug {
  line-height: 1.375
}

.leading-none {
  line-height: 1
}

.leading-3 {
  line-height: .75rem
}

.leading-5 {
  line-height: 1.25rem
}

.leading-loose {
  line-height: 2
}

.leading-tight {
  line-height: 1.25
}

.leading-8 {
  line-height: 2rem
}

.tracking-wider {
  letter-spacing: 0.05em
}

.tracking-wide {
  letter-spacing: 0.025em
}

.tracking-normal {
  letter-spacing: 0em
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity))
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity))
}

.text-marketplaceColorDark {
  --tw-text-opacity: 1;
  color: rgb(13 75 13 / var(--tw-text-opacity))
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity))
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity))
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity))
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity))
}

.text-marketplaceColor {
  --tw-text-opacity: 1;
  color: rgb(5 192 5 / var(--tw-text-opacity))
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity))
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.opacity-70 {
  opacity: 0.7
}

.opacity-0 {
  opacity: 0
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline {
  outline-style: solid
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.duration-100 {
  transition-duration: 100ms
}

.duration-200 {
  transition-duration: 200ms
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
}

.focus-within\:border-gray-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.focus-within\:text-gray-700:focus-within {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

.hover\:cursor-pointer:hover {
  cursor: pointer
}

.hover\:border-marketplaceColorDark:hover {
  --tw-border-opacity: 1;
  border-color: rgb(13 75 13 / var(--tw-border-opacity))
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity))
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.hover\:border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.hover\:border-black:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity))
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity))
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.hover\:bg-marketplaceColorDark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(13 75 13 / var(--tw-bg-opacity))
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity))
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity))
}

.hover\:text-marketplaceColorDark:hover {
  --tw-text-opacity: 1;
  color: rgb(13 75 13 / var(--tw-text-opacity))
}

.hover\:no-underline:hover {
  text-decoration-line: none
}

.focus\:border-marketplaceColorDark:focus {
  --tw-border-opacity: 1;
  border-color: rgb(13 75 13 / var(--tw-border-opacity))
}

.focus\:border-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.focus\:border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity))
}

.focus\:bg-gray-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity))
}

.focus\:bg-marketplaceColorDark:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(13 75 13 / var(--tw-bg-opacity))
}

.focus\:bg-gray-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed
}

.disabled\:border-marketplaceColorLight:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(64 227 64 / var(--tw-border-opacity))
}

.disabled\:border-gray-300:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity))
}

.disabled\:bg-marketplaceColorLight:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(64 227 64 / var(--tw-bg-opacity))
}

.disabled\:bg-gray-300:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity))
}

.group:hover .group-hover\:border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

@media (min-width: 640px) {
  .sm\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .sm\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .sm\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .sm\:rounded-lg {
    border-radius: 0.5rem
  }

  .sm\:p-12 {
    padding: 3rem
  }

  .sm\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  .sm\:shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }
}

@media (min-width: 768px) {
  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .md\:ml-auto {
    margin-left: auto
  }

  .md\:mr-auto {
    margin-right: auto
  }

  .md\:mt-8 {
    margin-top: 2rem
  }

  .md\:w-64 {
    width: 16rem
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:items-center {
    align-items: center
  }

  .md\:gap-1 {
    gap: 0.25rem
  }

  .md\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }
}

@media (min-width: 1024px) {
  .lg\:block {
    display: block
  }

  .lg\:hidden {
    display: none
  }

  .lg\:flex-row {
    flex-direction: row
  }
}